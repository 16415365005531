import TKIcon from "../../TKIcon/TKIcon";
import {
  pdf,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import React from "react";
import download from "downloadjs";
import { Height, Rotate90DegreesCcw } from "@material-ui/icons";
var QRCode = require("qrcode");

Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingHorizontal: 80,
    fontFamily: "Roboto",
  },
  title: {
    fontSize: 24,
    paddingTop: 35,
    textAlign: "center",
  },
  text: {
    fontSize: 10,
    right: "0px",
    width: "100px",
    transform: "rotate(-90deg)",
    bottom: "-40px",
  },
  text2: {
    fontSize: 10,
    right: "-60px",
    bottom: "-77px",
    transform: "rotate(-90deg)",
  },

  text3: {
    fontSize: 10,
    width: "40px",
    height: "40px",
    bottom: "128px",
    right: "-30px",
  },
  textAlt: {
    fontSize: 7,
    right: "0px",
    width: "100px",
    transform: "rotate(-90deg)",
    bottom: "-40px",
  },
});

const CardPdf = ({ user, card, qrData, ...props }) => (
  <Document>
    <Page size="A8" style={styles.body}>
      <Text style={styles.title}></Text>
      <Text style={styles.title}></Text>
      {user.name.length + user.surname.length < 15 ? (
        <Text style={styles.text}>{`${user.name} ${user.surname}`}</Text>
      ) : (
        <Text style={styles.textAlt}>{`${user.name} ${user.surname}`}</Text>
      )}
      <Text style={styles.text2}>{`${card.number}`}</Text>

      <View>
        <Image src={{ uri: qrData }} style={styles.text3} />
      </View>
    </Page>
  </Document>
);

const TKDownloadCardButton = ({ user, card, ...props }) => {
  const onClick = () => {
    const pdfWindow = window.open();

    QRCode.toDataURL(user._id, async function (err, url) {
      const doc = <CardPdf user={user} card={card} qrData={url} />;
      const asPdf = pdf(doc);
      const blob = await asPdf.toBlob();
      // download(blob, `${user.name}-${user.surname}-kart`);
      const file = blob;
      const fileURL = URL.createObjectURL(file);
      pdfWindow.location.href = fileURL;
    });
  };

  return (
    <TKIcon
      name="print"
      color="#969fa6"
      className="tk_print"
      size="25"
      title="Kartı Bas"
      onClick={() => onClick()}
    />
  );
};

export default TKDownloadCardButton;
